<template>
  <div class="swiper">
    <el-carousel indicator-position="outside">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <!-- <h3>{{ item.src }}</h3> -->
        <!-- <img src="./../../public/img/home/swiper_img1.png" alt=""> -->
        <div v-if="isContact" class="contactText">
          <div class="title">
            <span>Contact</span>
            <span>US</span>
          </div>
          <div class="content">
            Environmental Pollutants Quantum Genome Database
          </div>
        </div>
        <el-link :href="item.url" :underline="false" target="_blank">
          <el-image
            style="width: 100%; height: 100%;"
            :src="item.image"
            fit="cover"
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-link>
        <!-- <img :src="item.image" alt="" /> -->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "swiper",
  props: {
    bannerList: {
      type: Array,
      default() {
        return [];
      }
    },
    isContact: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    list() {
      //  console.log("轮播图",this.bannerList)
      if (this.bannerList.length > 0) {
        return this.bannerList;
      }
      return this.defaultList;
    }
  },
  data() {
    return {
      defaultList: [
        {
          image: require("./../../public/img/home/swiper_img1.png")
        },
        {
          image: require("./../../public/img/home/swiper_img1.png")
        },
        {
          image: require("./../../public/img/home/swiper_img1.png")
        },
        {
          image: require("./../../public/img/home/swiper_img1.png")
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.el-carousel__item {
  ::v-deep img {
    width: 100%;
    height: 100%;
  }
}

::v-deep .el-link {
  display: inline;
}

::v-deep .image-slot {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel__indicators--outside .el-carousel__indicator:hover button {
  background-color: #0495f3 !important;
}

.contactText {
  position: absolute;
  top: 40px;
  left: 60px;
  z-index: 100;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    flex-direction: column;
    span {
      font-size: 60px;
      font-weight: bolder;
    }
  }
  .content{
    margin-top: 30px;
    font-size: 16px;
    width: 250px;
  }
}
</style>
